import JSZip from "jszip";

export const csvExporter = ({ arrData, fileName }) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += [
    Object.keys(arrData[0]).join(","),
    ...arrData.map((item) => Object.values(item).join(",")),
  ]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "");

  const data = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", fileName);
  link.click();
};

export const fileDownloader = async ({ url, file }) => {
  const gerberLink = document.createElement("a");
  gerberLink.href = url;
  gerberLink.setAttribute("download", file);
  document.body.appendChild(gerberLink);
  gerberLink.click();
  URL.revokeObjectURL(gerberLink.href);
  gerberLink.remove();
};

export const getFileString = async (url) => {
  const data = await fetch(url);
  const svgString = await data.text();
  return svgString;
};

export const getFileBlob = async (url) => {
  const data = await fetch(url);
  const svgBlob = await data.blob();
  return svgBlob;
};

export const gerbersToZip = async (gerbers) => {
  const zip = new JSZip();
  for (let i = 0; i < gerbers.length; i++) {
    let file = new Blob([gerbers[i].gerber], {
      type: "application/vnd.gerber",
    });
    let filename = gerbers[i].file;
    zip.file(filename, file);
  }
  const response = await zip.generateAsync({type: 'blob'});
  const fileName = `zipped_gerbers.zip`;
  let zipData = new FormData();
  zipData.append("zipped_gerber_files", response, fileName)
  return zipData;
};